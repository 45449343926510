<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card class="v-card-bottom-30">
      <v-card-title>
        {{use_language.stock}}
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack('my-product')"> {{use_language.my_product}} </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ navigete }}
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- เลือกสินค้า -->
    <v-dialog v-model="addStockPopup.Dialog" scrollable :max-width="addStockPopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <v-select dense outlined hide-details   max-width="50px"
                      label="ค้นหาโดย"
                      v-model="StockSearchType"
                      :items="['sku', 'ชื่อ', 'barcode']"
                    ></v-select>
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field
                ref="refSearchStockModelPopup"
                :value="searchStockModelPopup"
                autocomplete="off"
                class="pl-2"
                dense
                hide-details
                @change="value => searchStockModelPopup = Trim_value(value)"
                @keyup.enter="PopupsearchStock(limits,0)"
              ></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn color="primary" class="mr-1 px-2" @click="PopupsearchStock(limits,0)">
                <v-icon center >mdi-magnify</v-icon>
                  {{use_language.search}}
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="addStockPopup.Dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addStockPopup.MaxHeight+'px'}">
          <v-data-table
            :headers="headers_select_product"
            :items="addStockPopup.Data"
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:item.selectProduct="{ item }">
            <v-checkbox v-model="item.selectProduct" color="red" hide-details @click="ProductSelectCheckbox(item,addStockPopup.Data.indexOf(item))"></v-checkbox>
          </template>
          <template v-slot:item.no="{ item }">
            {{ (addStockPopup.Data.indexOf(item)+1)+offsets }}
          </template>
          <template v-slot:item.photoLink="{ item }">
            <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" class="picsize mt-1">
            </a>
          </template>
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.prop1="{ item }">
            <span class="fn-13">
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
          </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

        <!-- Mobile -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-sm-and-up" :style="{height: addStockPopup.MaxHeight+'px'}">
          <v-data-iterator
            :items="addStockPopup.Data"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12">{{use_language.no_picture}} </span>
                        </a>
                        <div class="v-list-item__content">
                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                {{use_language.name_product}}
                              </div>
                              <div style="line-height: 1rem !important;">
                                : {{ item.name }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  {{use_language.type}}
                                </div>
                                <div>
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.sku}}
                                </div>
                                <div>
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.barcode}}
                                </div>
                                <div>
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.number_product}}
                                </div>
                                <div>
                                  <v-row>
                                    <v-col cols="6" class="pa-0">
                                      : {{ item.productCode }}
                                    </v-col>
                                    <v-col cols="6" class="pa-0"/>
                                  </v-row>
                                </div>
                                <v-btn v-if="item.selectProduct" x-small depressed color="primary" @click="ProductSelect(item,addStockPopup.Data.indexOf(item))"> {{use_language.choose}} </v-btn>
                                <v-btn v-else outlined x-small depressed color="primary" @click="ProductSelect(item,addStockPopup.Data.indexOf(item))"> {{use_language.choose}}  </v-btn>
                                <!-- <v-icon> mdi-checkbox-multiple-marked-circle-outline</v-icon> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card-title>

                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <!-- Mobile -->

        <div v-if="pageLength>1 && addStockPopup.Data.length>0" class="text-center pa-1 ma-0" @click="changePage()">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addStockPopup.loading" @click="Submit_addStockPopup()">
              {{use_language.add_product}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- from -->
    <v-form ref="addstockForm" lazy-validation>

      <!-- ข้อมูลทั่วไป -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="teal darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">{{use_language.dataProduct}}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>

          <v-row class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.product_type}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-radio-group row v-model="productMaster.isProductSetTemp">
                <v-radio v-for="i in productMaster.isProductSetList" color="info" :disabled="ChackEditRdo(productMaster.id)" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.product_picture}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <a id="pick-avatar">
                <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                  <img :src="photo.photo_link" class="picsize">
                </v-avatar>
                <v-avatar v-else class="picsize-hover" tile>
                 {{use_language.no_picture}}
                </v-avatar>
              </a>
              <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                @completed="handleCompleted"
                @error="handlerError"
                :cropper-options="cropperOptions"
                :labels="textButton"
                :upload-url="uploadurl"
                trigger="#pick-avatar"
              />
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
          
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"><span class="color-red">*</span> {{use_language.name_product}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.name"
                @change="value => productMaster.name = Trim_value(value)"
                :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                ref="productMastername"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.number_product}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.productCode"
                @change="value => productMaster.productCode = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> คุณสมบัติ1 : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.prop1"
                @change="value => productMaster.prop1 = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> คำอธิบายคุณสมบัติ1 : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.prop1Description"
                @change="value => productMasterItem.prop1Description = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14">คุณสมบัติ2 : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.prop2"
                @change="value => productMaster.prop2 = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> คำอธิบายคุณสมบัติ2 : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.prop2Description"
                @change="value => productMasterItem.prop2Description = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.code_cf}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.cfCode"
                @change="value => productMaster.cfCode = Trim_value(value)"
                ref="productMasterproductCode"
                outlined
                dense hide-details
                autocomplete="off"
                maxlength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.company}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.company"
                @change="value => productMaster.company = Trim_value(value)"
                ref="productMasterproducompany"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.brand}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMaster.brand"
                @change="value => productMaster.brand = Trim_value(value)"
                ref="productMasterprodubrand"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
 
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.selling_price}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.unitPrice"
                @change="value => productMasterItem.unitPrice = Trim_value(value)"
                ref="productMasterItemunitPrice"
                @keypress="isNumberWithDot($event)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.sku}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.sku"
                @change="value => productMasterItem.sku = Trim_value(value)"
                ref="productMasterItemsku"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.barcode}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.barcode"
                @change="value => productMasterItem.barcode = Trim_value(value)"
                ref="productMasterItembarcode"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
           
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.cubic1}}({{use_language.cm}}) : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic1"
                @change="value => productMasterItem.cubic1 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.cubic2}}({{use_language.cm}}) : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic2"
                @change="value => productMasterItem.cubic2 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
          
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.cubic3}}({{use_language.cm}}) : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.cubic3"
                @change="value => productMasterItem.cubic3 = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.weight}}({{use_language.gram}}) : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                :value="productMasterItem.weight"
                @change="value => productMasterItem.weight = Trim_value(value)"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

          <v-row v-if="productMaster.isProductSetTemp" class="py-2">
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"><span class="color-red">*</span> {{use_language.end_date}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-radio-group row v-model="productMaster.selectEndDate">
                <v-radio v-for="i in productMaster.checkEndDate" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
              </v-radio-group>
              <v-menu
                v-if="productMaster.selectEndDate"
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    outlined
                    hide-details
                    readonly
                    label=""
                    prepend-inner-icon="mdi-calendar"
                    @blur="endDate = parseDate(dateEnd)"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                    clearable
                    @click:clear="dateEnd = null"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="menuEndDate = false" @change="dateEnd=formatDate(endDate)" no-title :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>
          <v-row class="py-2" v-if="productMaster.isProductSetTemp">
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> เซ็ตแบบสุ่ม : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
             <v-radio-group row v-model="productMaster.isRandom">
                <v-radio v-for="i in productMaster.checkIsRandom" color="info" v-bind:key="i['value']" :label="i['label']" :value="i['value']"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>
          <v-row class="py-2" v-if="productMaster.isProductSetTemp &&productMaster.isRandom">
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> จำนวนชิ้นที่ต้องการสุ่ม : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
            <v-text-field style="width:100px;"
                :value="productMaster.randomQuantity"
                @change="value => productMaster.randomQuantity = Trim_value(value)"
                outlined
                ref="productMasterRandomQuantity"
                dense hide-details
                autocomplete="off"
                 type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>
          <v-row class="py-2">
            
            <v-col cols="12" md="2" class="py-0">
              <h3 class="fn-14"> {{use_language.note}} : </h3>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-textarea
                :value="productMaster.remark"
                @change="value => productMaster.remark = Trim_value(value)"
                ref="productMasterremark"
                outlined
                dense hide-details
                autocomplete="off"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2" class="py-0"/>
          </v-row>

        </v-card-text>
      </v-card>

      <!-- ข้อมูลสินค้า Product -->
      <v-card v-if="!productMaster.isProductSetTemp" elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-file-document</v-icon><span class="ml-3">{{use_language.info_product}}</span>
          <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto">

          <div v-if="!is_prop">

            <v-row class="py-2">
            
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.product_picture}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <a id="pick-avatar">
                  <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                    <img :src="photo.photo_link" class="picsize">
                  </v-avatar>
                  <v-avatar v-else class="picsize-hover" tile>
                   {{use_language.no_picture}}
                  </v-avatar>
                </a>
                <avatar-cropper
                  @uploading="handleUploading"
                  @uploaded="handleUploaded"
                  @completed="handleCompleted"
                  @error="handlerError"
                  :cropper-options="cropperOptions"
                  :labels="textButton"
                  :upload-url="uploadurl"
                  trigger="#pick-avatar"
                />
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
             
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.selling_price}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.unitPrice"
                  @change="value => productMasterItem.unitPrice = Trim_value(value)"
                  ref="productMasterItemunitPrice"
                  @keypress="isNumberWithDot($event)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
             
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.sku}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.sku"
                  @change="value => productMasterItem.sku = Trim_value(value)"
                  ref="productMasterItemsku"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.barcode}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.barcode"
                  @change="value => productMasterItem.barcode = Trim_value(value)"
                  ref="productMasterItembarcode"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.cubic1}}({{use_language.cm}}) : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic1"
                  @change="value => productMasterItem.cubic1 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.cubic2}}({{use_language.cm}}) : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic2"
                  @change="value => productMasterItem.cubic2 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
             
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.cubic3}}({{use_language.cm}}) : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.cubic3"
                  @change="value => productMasterItem.cubic3 = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
             
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.weight}}({{use_language.gram}}) : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.weight"
                  @change="value => productMasterItem.weight = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.number_notifications}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.alertQuantity"
                  @change="value => productMasterItem.alertQuantity = Trim_value(value)"
                  ref="productMasterItemalertQuantity"
                  @keypress="isNumberWNoDot($event)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> หมวดหมู่ : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">

                <v-select
                              v-model="CategorySelect"
                              :items="Category_list"
                              item-text="categoryName"
                              item-value="id"
                              persistent-hint
                              return-object
                              dense hide-details
                              outlined
                              single-line
                            ></v-select>
              </v-col>
            <v-col cols="12" md="2" class="py-0"/>
            </v-row>
            <v-row class="py-2">
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> ประเภท : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-radio-group row v-model="productMasterItem.isBox">
                  <v-radio label="สินค้า" :value=false></v-radio>
                  <v-radio label="กล่องบรรจุภัณฑ์" :value=true></v-radio>
                </v-radio-group>  
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>


            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.variants}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-btn block text class="sshoveroption" @click="OpenOption(true)"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เปิดใช้งานตัวเลือกสินค้า </span></v-btn>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

          </div>
          <div v-else>

            <v-row class="py-2" v-for="(i , indexi) in option" :key="indexi">
         
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.choice}} {{ i.prop }} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="pa-4 pt-0 bgc-fa">

                <v-row class="px-0">
                  <v-spacer/>
                  <a @click="CloseOption(i.prop)"><v-icon class="close-option" size="15">fa-times</v-icon></a>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3" class="pa-0">
                    <h3 class="color-b3"> {{use_language.name}} : </h3>
                  </v-col>
                  <v-col cols="12" md="9" class="pa-0">
                    <v-row>
                      <v-col cols="11" class="pt-1 pb-2">
                        <v-text-field
                          :value="i['name']"
                          @change="value => i['name'] = Trim_value(value)"
                          @input="ChangeProp(i.prop,$event)"
                          :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                          outlined
                          dense hide-details
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="pa-0"/>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3" class="pa-0">
                    <h3 class="color-b3"> {{use_language.choice}} : </h3>
                  </v-col>
                  <v-col cols="12" md="9" class="pa-0">
                    <v-row v-for="(ii , indexii) in i.data" :key="indexii">
                      <v-col cols="11" class="pt-1 pb-2">
                        <v-text-field
                          :value="ii['name']"
                          @change="value => ii['name'] = Trim_value(value)"
                          @input="ChangeOptionInProp(i.prop,indexii,$event)"
                          :rules="[ v => Trim_rules(v) || 'กรุณาระบุ', ]"
                          outlined
                          dense hide-details
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" v-if="!productMaster.id && ii['is_base']" class="pa-0">
                        <span v-if="i.data.length>1"><v-icon class="color-b3" size="15">fa-trash-alt</v-icon></span>
                      </v-col>
                      <v-col cols="1" v-else class="pa-0">
                        <a v-if="i.data.length>1" @click="Del_Option2(i.prop,indexii)"><v-icon class="color-b3 hover-close" size="15">fa-trash-alt</v-icon></a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="3"/>
                  <v-col cols="12" md="9" class="pa-0">
                    <v-row>
                      <v-col cols="11" class="pt-1 pb-2">
                        <v-btn block text class="sshoveroption" @click="add_prop_description(i.prop)"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เพิ่มตัวเลือก </span></v-btn>
                      </v-col>
                      <v-col cols="1"/>
                    </v-row>
                  </v-col>
                </v-row>

                <div v-if="i.prop==1">
                  <v-row align="start" justify="start" class="pa-1">
                    <div v-for="(ip , indexip) in i.data" :key="indexip">
                      <a :id="'pick-avatar'+indexip" @click="UpdatePhoto(indexip)">
                        <v-avatar v-if="ip.photo_link!=null && ip.photo_link!=''" class="picsize-hover" tile>
                          <img :src="ip.photo_link" class="picsize">
                        </v-avatar>
                        <v-avatar v-else class="picsize-hover" tile>
                          <span v-if="ip.name!=null && ip.name!=''" class="text-cut"> {{ ip.name }} </span>
                          <span v-else>{{use_language.no_picture}} </span>
                        </v-avatar>
                      </a>
                      <avatar-cropper
                        @uploading="handleUploading"
                        @uploaded="handleUploaded"
                        @completed="handleCompleted"
                        @error="handlerError"
                        :cropper-options="cropperOptions"
                        :labels="textButton"
                        :upload-url="uploadurl"
                        :trigger="'#pick-avatar'+indexip"
                      />
                    </div>
                  </v-row>
                </div>

              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2" v-if="option.length==1">
             
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.choice}} 2 : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-btn block text class="sshoveroption" @click="AddOption()"><v-icon size="12" class="color-blue mr-1">fa-plus</v-icon><span class="color-blue fn-14"> เพิ่ม </span></v-btn>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.number_notifications}} : </h3>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :value="productMasterItem.alertQuantity"
                  @change="value => productMasterItem.alertQuantity = Trim_value(value)"
                  @keypress="isNumberWNoDot($event)"
                  ref="productMasterItemalertQuantity"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="py-0"/>
            </v-row>

            <v-row class="py-2">
              
              <v-col cols="12" md="2" class="py-0">
                <h3 class="fn-14"> {{use_language.variant_list}} : </h3>
              </v-col>
              <v-col cols="12" md="10" class="py-0">

                <!-- PC hidden-xs-only -->
                <v-data-table
                  :headers="headers_option"
                  :items="dataset_option"
                  :items-per-page="9999999999"
                  hide-default-footer
                  class="packhai-border-table-add hidden-xs-only"
                  >
                  <template v-slot:item.unitPrice="{ item }">
                    <v-text-field
                      :value="item.unitPrice"
                      @change="value => item.unitPrice = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.sku="{ item }">
                    <v-text-field
                      :value="item.sku"
                      @change="value => item.sku = Trim_value(value)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.barcode="{ item }">
                    <v-text-field
                      :value="item.barcode"
                      @change="value => item.barcode = Trim_value(value)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>

                  <template v-slot:item.cubic1="{ item }">
                    <v-text-field
                      :value="item.cubic1"
                      @change="value => item.cubic1 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.cubic2="{ item }">
                    <v-text-field
                      :value="item.cubic2"
                      @change="value => item.cubic2 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.cubic3="{ item }">
                    <v-text-field
                      :value="item.cubic3"
                      @change="value => item.cubic3 = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                  <template v-slot:item.weight="{ item }">
                    <v-text-field
                      :value="item.weight"
                      @change="value => item.weight = Trim_value(value)"
                      @keypress="isNumberWithDot($event)"
                      dense hide-details
                      autocomplete="off"
                      class="pa-1"
                    />
                  </template>
                </v-data-table>

                <!-- Mobile hidden-sm-and-up -->
                <v-data-iterator
                  :items="dataset_option"
                  :items-per-page="9999999999"
                  hide-default-header
                  hide-default-footer
                  class="hidden-sm-and-up"
                  >
                  <template v-slot:default="props">
                    <v-row class="ma-0">
                      <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                        <v-card>
                          <v-card-text>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{ headers_option[0]["text"] }}
                              </v-col>
                              <v-col cols="8" class="pa-0">
                                : {{ item.prop1Description }}
                              </v-col>
                            </v-row>

                            <v-row v-if="option.length==2">
                              <v-col cols="4" class="pa-0">
                                {{ headers_option[1]["text"] }}
                              </v-col>
                              <v-col cols="8" class="pa-0">
                                : {{ item.prop2Description }}
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.selling_price}}
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.unitPrice"
                                    @change="value => item.unitPrice = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.sku}}
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.sku"
                                    @change="value => item.sku = Trim_value(value)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.barcode}}
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.barcode"
                                    @change="value => item.barcode = Trim_value(value)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.cubic1}}({{use_language.cm}})
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic1"
                                    @change="value => item.cubic1 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.cubic2}}({{use_language.cm}})
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic2"
                                    @change="value => item.cubic2 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.cubic3}}({{use_language.cm}})
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.cubic3"
                                    @change="value => item.cubic3 = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="4" class="pa-0">
                                {{use_language.weight}}({{use_language.gram}})
                              </v-col>
                              <v-col cols="8" class="packhai-showtime pa-0">
                                :
                                <div>
                                  <v-text-field
                                    :value="item.weight"
                                    @change="value => item.weight = Trim_value(value)"
                                    @keypress="isNumberWithDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="pa-1 center-input"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>

              </v-col>
            </v-row>

          </div>

        </v-card-text>
      </v-card>

      <!-- ข้อมูลสินค้า Product Set -->
      <v-card v-else elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">{{use_language.info_product}}</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="px-2" @click="OpenPopup(limits,0)">
            <v-icon size="22" class="mr-1">mdi-plus</v-icon>
            {{use_language.add_item}}
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto  pa-0">

          <!-- PC hidden-xs-only -->

          <v-data-table
            :headers="headers"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
            <template v-slot:item.del="{ item }">
              <a @click="DeleteProduct(item.name,dataset.indexOf(item))"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
            </template>
            <template v-slot:item.no="{ item }">
              {{ formatMoney(dataset.length - dataset.indexOf(item)) }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              {{item.name}}
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-text-field
                :value="item.quantity"
                :rules="[ v => !!v && v!=0 || 'กรุณาระบุ', ]"
                @change="value => item.quantity = Trim_value(value)"
                @keypress="isNumberWNoDot($event)"
                dense hide-details
                autocomplete="off"
              />
            </template>
            <template v-slot:item.created="{ item }">
             {{item.created}}
             <br>
             {{item.createdByName}}
            </template>
            <template v-slot:item.updated="{ item }">
             {{item.updated}}
             <br>
             {{item.updatedByName}}
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
          <v-data-iterator
            v-if="size=='xs' || size=='sm'"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12">{{use_language.no_picture}} </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0" >

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                {{use_language.name}}
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.name != null">: {{ item.name }}</span>
                                    </div>
                                  </template>
                                  <span v-if=" item.name != null">{{ item.name }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  {{use_language.type}}
                                </div>
                                <div class="cut-text">
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.number_product}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.productCode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.sku}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.barcode}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.quantity}}
                                </div>
                                <div>
                                  :
                                  <v-text-field
                                    :value="item.quantity"
                                    :rules="[ v => !!v && v!=0 || 'กรุณาระบุ', ]"
                                    @change="value => item.quantity = Trim_value(value)"
                                    @keypress="isNumberWNoDot($event)"
                                    dense hide-details
                                    autocomplete="off"
                                    class="center-input"
                                  />
                                </div>
                                <v-spacer/>
                                <a @click="DeleteProduct(item.name,dataset.indexOf(item))"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>


                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
      </v-card>

        <!-- สต๊อก-->
        <v-card v-if="!productMaster.isProductSetTemp" elevation="1" class="v-card-margin-bottom-30">
        <v-card-title class="height-57">
          <v-icon size="22" color="red darken-3">mdi-pencil-plus</v-icon><span class="ml-3">จำนวนสต๊อกสินค้าแต่ละคลัง</span>
          <v-spacer/>
          <a @click="stockqq.active = !stockqq.active">
            <v-switch  v-model="stockqq.active" readonly color="success" ></v-switch>
          </a>
        </v-card-title>
        <v-divider/>

        <v-card-text >
          <v-row >
            <v-col cols="12"   v-if=" stockqq.active">
              <div class="mb-5" v-for=" warehouseItem  in stockqq.data_warehouse_list" :key="warehouseItem.warehouseID">
                <h3 >{{ warehouseItem.name }}</h3>
                
               
                  <v-data-table 
                        :headers="stockqq.headers"
                        :items="warehouseItem.dataset"
                        class="elevation-0 packhai-border-table "
                        hide-default-footer
                        :items-per-page="10000"
                        mobile-breakpoint="0"
                        style="border:1px solid #bdbdbd; width:500px"
                      >
                        <template v-slot:item.stockquantity="{  item }" >
                          <v-text-field
                              :value="item.stockquantity"
                              @change="value => item.stockquantity = Trim_value(value)"
                              @keypress="isNumberWNoDot($event)"
                               outlined 
                               hide-details
                              autocomplete="off"
                              class="mx-5 my-5"
                            />
                        </template>
                        <template v-slot:item.buffer="{ item }" >
                          <v-text-field
                              :value="item.buffer"
                              @change="value => item.buffer = Trim_value(value)"
                              @keypress="isNumberWNoDot($event)"
                              outlined
                              hide-details
                              autocomplete="off"
                              class="mx-5 my-5"
                            />  
                        </template>
                  </v-data-table>
                 
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-form>

    <!-- sumbit -->
 
    <v-row>
      <v-col cols="12"  align="right" >
        <v-btn :disabled='!authorize' :elevation="1" color="primary" class="px-5" @click="UpdateStock(productMaster.isProductSetTemp)">
          <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
          <span class="fn-14"><b> {{use_language.record}} </b></span>
        </v-btn>
      </v-col>
    </v-row>
    

  </div>
</template>

<script>
  import axios from "axios"
  import Swal from "sweetalert2"
  import AvatarCropper from "vue-avatar-cropper" 
  import Loading from "@/website/components/Loading"
  import { globalService, stockService_dotnet,shopService_dotnet } from "@/website/global"
  import { isNumberWithDot, isNumberWNoDot, formatMoney, DateNowNotNull, Trim_value, Trim_rules, formatDatetime, formatDate_thai, formatDate_eng ,get_languages} from "@/website/global_function"
  export default {
    components: {
      Loading,
      AvatarCropper
    },
    data: () => ({
      // page
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      navigete: null,

      use_language: null,
      set_language: null,

      //เช็คสิทธิ์
      authorize:false,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',
      StaffShopID:parseInt(localStorage.getItem('staff_id')),
      productMaster: {
        "id": 0,
        "shopId": parseInt(localStorage.getItem('shop_id')),
        "name": null,
        "productCode": null,
        "cfCode": null,
        "brand": null,
        "company": null,
        "prop1": null,
        "prop2": null,
        "remark": null,
        // "ProductCategoryID":null,
        "isProductSet": 0,
        "isProductSetTemp": 0,
        "isProductSetList": null,
        "promotionEndDate": null,
        "selectEndDate": 0,
        "isRandom":false,
        "checkEndDate": [
          { "value": 0 , "label": "ไม่มีสิ้นสุด" },
          { "value": 1 , "label": "สิ้นสุดวันที่" },
        ],
        "checkIsRandom": [
          { "value": false , "label": "ไมใช่" },
          { "value": true , "label": "ใช่" },
        ],
      },

      // วันที่สิ้นสุด promotionEndDate
      endDate: null,
      dateEnd: null,
      menuEndDate: false,
      StockSearchType:'sku',
      productMasterItem: {
        "id": 0,
        "productMasterId": 0,
        "photoLink": null,
        "isBox": false,
        "unitPrice": null,
        "barcode": null,
        "sku": null,
        "cubic1": null,
        "cubic2": null,
        "cubic3": null,
        "weight": null,
        "alertQuantity": null,

      },

      // option
      is_prop: false,
      headers_option: [],
      dataset_option: [],
      option: [],

      // Product Set
      headers: [
        { text: 'ลบ',align: 'center',sortable: false,value: 'del', width: '60px'},
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', width: '350px'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', width: '250px'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '90px'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '90px'},
        { text: 'วันที่สร้าง',align: 'center',sortable: false,value: 'created', width: '160px'},
        { text: 'วันที่อัพเดท',align: 'center',sortable: false,value: 'updated', width: '160px'},
      ],
      dataset: [],

      // popup
      headers_select_product: [
        { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
      ],
      Popupsearch: null,
      searchStockModelPopup: null,
      addStockPopup: {
        "MaxWidth": "50%",
        "MaxHeight": 90,
        "Dialog": false,
        "Header": [
          { text: 'ชื่อ',align: 'center',sortable: false,value: 'Name',},
        ],
        "Select_Product": [],
        "Data": [],
        "ProductMasterItemID_List": [],
        "loading": false,
      },

      // AvatarCropper
      cropperOptions:{
        background:false,
        viewMode:1,
        aspectRatio: 1,
        autoCropArea: 1,
        movable: false,
        zoomable: false
      },
      textButton: {
        submit:"ตกลง"
        ,cancel:"ยกเลิก"
      },
      uploadurl: globalService+"uploadPicture",
      message: "ready",
      photo: {
        "is_base": false,
        "photo_link": null,
        "photo_uploand": {
          "file":[],
          "fileSize":0,
          "from_Picture":{},
          "from_path":''
        },
      },
      countStockshop: 0,

      stockqq: {
        active:true,
        buffer:null,
        stockquantity:null,
        data_warehouse_list:[],
        headers: [
        { text: 'จำนวน Buffer',align: 'center',sortable: false,value: 'buffer', width: '60px'  },
        { text: 'จำนวน สต๊อก',align: 'center',sortable: false,value: 'stockquantity', width: '80px'},
      ],
        dataset:[         
           {
            "id":null,
            "buffer": null,
            "stockquantity":null,
          }],
      },

      // paginations
      checkPage: true,
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 15,
      offsets: 0,

      part_return: null,

      //หมวดหมู่
      CategorySelect :{"id": 0, "categoryName": "--เลือกหมวดหมู่--"},
      Category_list :  [{"id": 0, "categoryName": "--เลือกหมวดหมู่--"}],

      // ขนาดจอ
      size: 'lg',
      window: {
        width: 0,
        height: 0
      },
    }),
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    async created() {
      //เก็บภาษามาไว้ใน use_language
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));
      this.set_language = localStorage.getItem("set_language");
      this.productMaster.isProductSetList= [
          { "value": 0 , "label": this.use_language.general_product },
          { "value": 1 , "label": this.use_language.set_products } 
        ];
      //เรียกใช้category
      this.GetCategory();
      //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
      this.get_authorize_staffshop();
      await this.get_warehouse_list();

      window.addEventListener('resize', this.handleResize)
      this.handleResize();
      if (this.window.width<960){this.addStockPopup.MaxWidth='100%'}
      else{this.addStockPopup.MaxWidth='70%'}
      if (this.size=='xs' || this.size=='sm'){
        this.limits = 25
        this.addStockPopup.MaxHeight = (90*this.window.height)/100
      }else{
        this.addStockPopup.MaxHeight = (this.addStockPopup.MaxHeight*this.window.height)/100
        // cal limits
        this.limits = parseInt((((((this.addStockPopup.MaxHeight-57)-52)-56)-48)-10)/48)
      }

      var data = JSON.parse(localStorage.getItem("data"))
      this.productMaster.isProductSetTemp = data.isProductSet
      this.part_return = data.part_return
      if (data!=null && data.productMasterId!=null && data.productMasterItemId!=null){
        this.productMaster.id = data.productMasterId
        this.productMasterItem.id = data.productMasterItemId
      } else {
        this.productMaster.id = 0
        this.productMasterItem.id = 0
      }

      this.navigete = "เพิ่มสินค้า"
      if (this.productMaster.id!=0 && this.productMasterItem.id!=0){
        this.navigete = "แก้ไขสินค้า"
        await this.Load_Data()
      }


      this.page_loading = false
    },
    methods: {
      // loaddata
      async Load_Data() {

        await axios.all([
          axios.post(stockService_dotnet+'Stock/get-myproduct', {
            'shopId': localStorage.getItem('shop_id'),
            'productMasterId': this.productMaster.id,
            'productMasterItemId': this.productMasterItem.id,
          }, {headers: this.header_token}),

          

          axios.post(stockService_dotnet+'Stock/get-productMasterItem-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-vstockmaster-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-list', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/count-stockshopitem', {
            'productMasterId': this.productMaster.id,
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((myproduct, product, productsetVStockMaster, productset, countStockshop) => {
          myproduct = myproduct.data
          product = product.data
          productsetVStockMaster = productsetVStockMaster.data
          productset = productset.data
          //set Category
          this.CategorySelect = {"id": 0, "categoryName": "--เลือกหมวดหมู่--"}
            if(product[0].productCategoryID != null){
            this.CategorySelect.id = product[0].productCategoryID
          }
          
          this.countStockshop = countStockshop.data
          // set productMaster
          this.productMaster.name = myproduct.name
          this.productMaster.productCode =  myproduct.productCode
          this.productMaster.cfCode =  myproduct.cfCode
          this.productMaster.brand =  myproduct.brand
          this.productMaster.company =  myproduct.company
          this.productMaster.prop1 =  myproduct.prop1
          this.productMasterItem.prop1Description = myproduct.prop1Description
          this.productMaster.prop2 =  myproduct.prop2
          this.productMaster.prop2Description = myproduct.prop2Description
          this.productMaster.isProductSet = myproduct.isProductSet
          this.productMaster.isRandom = myproduct.isRandom
          this.productMaster.randomQuantity = myproduct.randomQuantity
          this.productMasterItem.remark =  myproduct.remark
          if (myproduct.promotionEndDate!=null){
            this.dateEnd = this.formatDate_thai(myproduct.promotionEndDate)
            this.endDate = this.formatDate_eng(myproduct.promotionEndDate)
            this.productMaster.selectEndDate = 1
          }

          this.productMasterItem.alertQuantity = myproduct.alertQuantity==0 ? null : myproduct.alertQuantity
         
          if ((product.length==1 &&  (myproduct.prop1==null || myproduct.prop1=="")) || (product.length==1 &&this.productMaster.isProductSet )){
            // set productMasterItem
           
            this.productMasterItem.productMasterId = myproduct.productMasterId
            this.productMasterItem.unitPrice = myproduct.unitPrice==null ? null : parseFloat(myproduct.unitPrice).toFixed(2)
            this.productMasterItem.barcode =  myproduct.barcode
            this.productMasterItem.sku =  myproduct.sku
            this.productMasterItem.photoLink =  null
            this.productMasterItem.cubic1 = myproduct.cubic1
            this.productMasterItem.cubic2 = myproduct.cubic2
            this.productMasterItem.cubic3 = myproduct.cubic3
            this.productMasterItem.weight = myproduct.weight
            this.productMasterItem.stockList = this.stockqq.data_warehouse_list
            if (myproduct.photoLink!=null){
              this.productMasterItem.photoLink = myproduct.photoLink
              this.photo.photo_link = myproduct.photoLink
              this.photo.is_base = true
              this.photo.photo_uploand.file = []
              this.photo.photo_uploand.fileSize = 0
              this.photo.photo_uploand.from_Picture = {}
              this.photo.photo_uploand.from_path = ""
            }
            if(product.stockList!=null && product.stockList.length>0){
              for (let index = 0; index < product.stockList.length; index++) {
                for (let i = 0; i <  this.stockqq.data_warehouse_list.length; i++) {
                 if(product[index].warehouseID ==this.stockqq.data_warehouse_list[i].warehouseID ){
                  this.stockqq.data_warehouse_list[i].dataset = []
                  this.stockqq.data_warehouse_list[i].dataset.push({
                    "id":1,
                    "productMasterItemID": product[i].id,
                    "prop": null,
                    "index":null,
                    "prop2": null,
                    "index2": null,
                    "prop1Description": null,
                    "prop2Description":null,
                    "buffer":product[index].buffer,
                    "stockquantity": product[index].quantity
                  }) 
                 }
                }
                product.stockList[index]
              }
            }
           
         
          }else{
            
            // set product option
            this.is_prop = true
            this.option = []
            if (myproduct.prop1!=null){
              this.PushEditOption(1,myproduct.prop1)
            }
            if (myproduct.prop2!=null){
              this.PushEditOption(2,myproduct.prop2)
            }

            // หา prop1 prop2Description
            var prop1 = []
            var prop2 = []
            for (var i in product){
              // หา prop1 photo_link
              var no1 = 0
              for (var p1 in prop1){
                if (prop1[p1]["name"]==product[i]["prop1Description"]){
                  no1 += 1
                }
              }
              if (no1==0){
                prop1.push({
                  "name": product[i]["prop1Description"],
                  "photo_link": product[i]["photoLink"]
                })
              }

              // หา prop2 photo_link
              var no2 = 0
              for (var p2 in prop2){
                if (prop2[p2]["name"]==product[i]["prop2Description"]){
                  no2 += 1
                }
              }
              if (no2==0){
                prop2.push({
                  "name": product[i]["prop2Description"],
                  "photo_link": product[i]["photoLink"]
                })
              }
            }

            // ใส่ ตัวเลือก prop1
            for (var i1 in prop1){
              this.option[0]["data"].push({
                "is_base": true,
                "name": prop1[i1]["name"],
                "photo_link_temp": prop1[i1]["photo_link"],
                "photo_link": prop1[i1]["photo_link"],
                "photo_uploand": {
                  "file":[],
                  "fileSize":0,
                  "from_Picture":{},
                  "from_path":''
                }
              })
            }
            // ใส่ ตัวเลือก prop2
            if (this.option.length>1){
              for (var i2 in prop2){
                if (prop2[i2]!=null){
                  this.option[1]["data"].push({
                    "is_base": true,
                    "name": prop2[i2]["name"],
                    "photo_link_temp": prop2[i2]["photo_link"],
                    "photo_link": prop2[i2]["photo_link"],
                    "photo_uploand": {
                      "file":[],
                      "fileSize":0,
                      "from_Picture":{},
                      "from_path":''
                    }
                  })
                }
              }
            }

            // สร้าง headers
            if (this.option.length==1){
              this.headers_prop1()
              this.headers_option[0]["text"] = this.option[0]["name"]
              this.stockqq.headers[0]["text"] = this.option[0]["name"]
            }else if (this.option.length==2){
              this.headers_prop2()
              this.headers_option[0]["text"] = this.option[0]["name"]
              this.headers_option[1]["text"] = this.option[1]["name"]
              this.stockqq.headers[0]["text"] = this.option[0]["name"]
              this.stockqq.headers[1]["text"] = this.option[1]["name"]
            }

            // สร้าง data-table
            for (var i_pd in product){
              for (var i_p1 in prop1){
                if (product[i_pd]["prop1Description"]==prop1[i_p1]["name"]){
                  product[i_pd]["prop"] = 1
                  product[i_pd]["index"] = parseInt(i_p1)
                }
              }
              for (var i_p2 in prop2){
                if (product[i_pd]["prop2Description"]==prop2[i_p2]["name"]){
                  product[i_pd]["prop2"] = prop2[i_p2] != null ? 2 : prop2[i_p2]
                  product[i_pd]["index2"] = prop2[i_p2] != null ? parseInt(i_p2) : prop2[i_p2]
                }
              }
            }
            // order by index prop1

           
            this.dataset_option = this.OrderByData(product)
            console.log( this.dataset_option);
            console.log(this.stockqq.data_warehouse_list);

            this.stockqq.data_warehouse_list.forEach(element => {
                element.dataset=[];

                var counter=1;
                for (let d_index = 0; d_index < this.dataset_option.length; d_index++) 
                {

                  var buffer=null;
                  var quantity=null;
                 
                

                  for (let p_index = 0; p_index < product.length; p_index++) {
                    if(product[p_index].id ==this.dataset_option[d_index].id )
                    {
                      product[p_index].stockList.forEach(element1 => {
                          if(element1.warehouseID == element.warehouseID){
                            buffer= element1.buffer;
                            quantity = element1.quantity
                          }
                      });
                    }
                  }

                  element.dataset.push({
                    "id":counter,
                    "productMasterItemID": this.dataset_option[d_index].id,
                    "prop": this.dataset_option[d_index].prop,
                    "index":this.dataset_option[d_index].index,
                    "prop2": this.dataset_option[d_index].prop2,
                    "index2": this.dataset_option[d_index].index2,
                    "prop1Description": this.dataset_option[d_index].prop1Description,
                    "prop2Description":this.dataset_option[d_index].prop2Description,
                    "buffer":buffer,
                    "stockquantity": quantity
                  })
                  counter++;
                }
               
            });
           
          

            this.photo.photo_link = this.dataset_option[0].photoLink
          }

          // set product-list
          for (var k in productsetVStockMaster){
            for (var j in productset){
              if (productsetVStockMaster[k]["productMasterId"]==productset[j]["productMasterId"] && productsetVStockMaster[k]["id"]==productset[j]["productMasterItemId"]){
                productsetVStockMaster[k]["quantity"] = parseInt(productset[j]["quantity"])
                productsetVStockMaster[k]["created"] =  productset[j]["created"] 
                productsetVStockMaster[k]["updated"] =  productset[j]["updated"] 
                productsetVStockMaster[k]["createdByName"] =  productset[j]["createdByName"] 
                productsetVStockMaster[k]["updatedByName"] =  productset[j]["updatedByName"] 
              }
            }
          }
          this.dataset = productsetVStockMaster
        }));
      },

      // save
      async UpdateStock(isProductSet){
        await this.CheckFocus()
       
        if (this.$refs.addstockForm.validate()) {
           
          var photoLink_del = null

          if (isProductSet){
            // เช็กรายการสินค้า
            if (this.dataset.length==0){
              Swal.fire({
                type: 'warning',
                title: 'เพิ่มรายการสินค้า',
                text: '',
              })
              return
            }

            // set productSet
            var productSet = []
            for (let i in this.dataset){
              var data = {}
              data["productMasterId"] = this.dataset[i]["productMasterId"]
              data["productMasterItemId"] = this.dataset[i]["id"]
              data["quantity"] = parseInt(this.dataset[i]["quantity"])
              data["refProductMasterId"] = this.productMaster.id
              data["refProductMasterItemId"] = this.productMasterItem.id
              productSet.push(data)
            }

            // เช็คภาพ
            if (this.photo.photo_link != this.productMasterItem.photoLink){
              photoLink_del = this.productMasterItem.photoLink
              this.productMasterItem.photoLink = this.photo.photo_link
            }

          }else{
            // add photo_link เข้าไป
            if (this.is_prop){
              for (var Odata in this.option[0]["data"]) {
                for (var product in this.dataset_option) {
                  if (Odata == this.dataset_option[product]["index"]){
                    this.dataset_option[product]["photo_link"] = this.option[0]["data"][Odata]["photo_link"]
                    this.dataset_option[product]["alertQuantity"] = this.productMasterItem.alertQuantity
                  }
                }
              }
            }else{
              // เช็คภาพ
              if (this.photo.photo_link != this.productMasterItem.photoLink){
                photoLink_del = this.productMasterItem.photoLink
                this.productMasterItem.photoLink = this.photo.photo_link
              }
            }

            var productMasterItem = []
            var alertQuantity = this.productMasterItem.alertQuantity==null || this.productMasterItem.alertQuantity.length=='' ? null : parseInt(this.productMasterItem.alertQuantity)
            // set prop
            if (!this.is_prop){
              this.productMaster.prop1 = null
              this.productMaster.prop2 = null
              this.productMaster.productCategoryID=this.CategorySelect.id
              this.productMasterItem.alertQuantity = alertQuantity
              this.productMasterItem.unitPrice = this.productMasterItem.unitPrice==null || this.productMasterItem.unitPrice=="" ? null : parseFloat(this.productMasterItem.unitPrice).toFixed(2)
              this.productMasterItem.sku = this.productMasterItem.sku==null || this.productMasterItem.sku=="" ? null : this.productMasterItem.sku
              this.productMasterItem.barcode = this.productMasterItem.barcode==null || this.productMasterItem.barcode=="" ? null : this.productMasterItem.barcode
              
              var stockList1 = []
              for (let w1 in this.stockqq.data_warehouse_list){
                stockList1.push({
                  "warehouseID": this.stockqq.data_warehouse_list[w1].warehouseID,
                  "buffer":  this.stockqq.data_warehouse_list[w1].dataset[0].buffer ,
                  "quantity":   this.stockqq.data_warehouse_list[w1].dataset[0].stockquantity 
                })
              }
              this.productMasterItem.stockList = stockList1
              
              productMasterItem.push(this.productMasterItem)
            } else {
              if (this.option.length==1){
                this.productMaster.prop1 = this.option[0]["name"]
                this.productMaster.prop2 = null
              }else if (this.option.length>1){
                this.productMaster.prop1 = this.option[0]["name"]
                this.productMaster.prop2 = this.option[1]["name"]
              }

              // แปลง type
              console.log(this.dataset_option);
              for (var pi in this.dataset_option){
                var unitPrice = this.dataset_option[pi]["unitPrice"]
                if (unitPrice!=null && unitPrice!='')
                { 
                  unitPrice = parseFloat(unitPrice).toFixed(2) 
                }

                var stockList = []
                for (var w in this.stockqq.data_warehouse_list){
                  
                 
                  stockList.push({
                    "warehouseID": this.stockqq.data_warehouse_list[w].warehouseID,
                    "buffer":  this.stockqq.data_warehouse_list[w].dataset[pi].buffer ,
                    "quantity":  this.stockqq.data_warehouse_list[w].dataset[pi].stockquantity 
                  })
                }
                productMasterItem.push({
                  "id": this.dataset_option[pi]["id"]==null? 0 : this.dataset_option[pi]["id"],
                  "productMasterId": 0,
                  "isBox": this.productMasterItem.isBox,
                  "prop": this.dataset_option[pi]["prop"],
                  "index": this.dataset_option[pi]["index"],
                  "prop2": this.dataset_option[pi]["prop2"],
                  "index2": this.dataset_option[pi]["index2"],
                  "prop1Description": this.dataset_option[pi]["prop1Description"],
                  "prop2Description": this.dataset_option[pi]["prop2Description"],
                  "alertQuantity": alertQuantity,
                  "unitPrice": unitPrice!=null && unitPrice!="" ? unitPrice : null,
                  "sku": this.dataset_option[pi]["sku"]!=null && this.dataset_option[pi]["sku"]!="" ? this.dataset_option[pi]["sku"] : null,
                  "barcode": this.dataset_option[pi]["barcode"]!=null && this.dataset_option[pi]["barcode"]!="" ? this.dataset_option[pi]["barcode"] : null,
                  "photoLink": this.dataset_option[pi]["photo_link"],
                  "cubic1": this.dataset_option[pi]["cubic1"],
                  "cubic2": this.dataset_option[pi]["cubic2"],
                  "cubic3": this.dataset_option[pi]["cubic3"],
                  "weight": this.dataset_option[pi]["weight"],
                  "stockList" : stockList,
                })
              }
            }
          }

          // เช็กวันที่ แปลง
          this.productMaster.promotionEndDate = null
          if (this.productMaster.selectEndDate){
            this.productMaster.promotionEndDate = new Date(this.endDate+" 00:00:00").toISOString()
          }

          // ckech isProductSet
          this.productMaster.isProductSet = this.productMaster.isProductSetTemp==1 ? true : false


          this.loading = true
          if (isProductSet){
            // update productset
            if(!this.productMaster.isRandom){
              this.productMaster.randomQuantity=null;
            }else{
              if(this.productMaster.randomQuantity==null || this.productMaster.randomQuantity==0){
                this.loading = false;
                await Swal.fire({
                  type: "error",
                  title: "ไม่สามารถทำรายการได้",
                  text: "กรุณาระบุจำนวนที่ต้องการสุ่มในเซ็ตนี้",
                });
                setTimeout(() => {
                  this.$refs.productMasterRandomQuantity.focus();
                }, 0)
                
                
              }
              var sumQuantity=0
              productSet.forEach(item => {
                if(item.quantity==null || item.quantity!=1){
                  item.quantity=1;
                }
                sumQuantity+=item.quantity
              });
           
            }
            let response = await axios.post(stockService_dotnet+'Stock/update-productset', {
            
              "productSet": productSet,
              "productMaster": this.productMaster,
              "productMasterItem": this.productMasterItem,
              "staffShopID": this.StaffShopID
            }, {headers: this.header_token} )
            if (response.status==200) {
              if (response.data.status=="error"){
                this.loading = false
                Swal.fire({
                  position: "top",
                  type: "error",
                  text: response.data.message[this.set_language],
                })
              } else {
                // ลบ ภาพ
                if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
                if (this.is_prop){ await this.DelPhotoOption() }

                this.loading = false
                Swal.fire({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'Success'
                })
                this.$router.push(this.part_return)
              }
            } else {
              this.loading = false
              Swal.fire({
                position: "top",
                type: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
          }else{
            // update product
             
            let response = await axios.post(stockService_dotnet+'Stock/update-product', {
              "productMaster": this.productMaster,
              "productMasterItem": productMasterItem,
            }, {headers: this.header_token} )
            if (response.status==200 || response.status==204) {
              if (response.data.status!=200){
                if (response.data.status==205){
                  this.Load_Data()
                  this.loading = false
                  Swal.fire({
                    position: "top",
                    type: "error",
                    text: response.data.text,
                  })
                } else if (response.data.status==203 || response.data.status==204) {
                  this.loading = false
                  Swal.fire({
                    position: "top",
                    type: "error",
                    text: response.data.text,
                  })
                }
              } else {
                // ลบ ภาพ
                if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
                await this.DelPhotoOptionTemp()

                this.loading = false
                Swal.fire({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'Success'
                })
                this.$router.push("/my-product")
              }
            } else {
              this.loading = false
              Swal.fire({
                position: "top",
                type: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
          }
        }
        else{
          if (isProductSet){
            for (let i in this.dataset){
               if(this.dataset[i]["quantity"]==null || this.dataset[i]["quantity"]=="" ){
                Swal.fire({
                  type: 'warning',
                  title: 'ยังใส่จำนวนไม่ครบ',
                  text: this.dataset[i].name+' ยังไม่ได้ใส่จำนวน',
                })
                return
               }
            }
          }
        }
      },
      async CheckFocus(){
        if (this.productMaster.name == "" || this.productMaster.name == null){
          this.$vuetify.goTo(this.$refs.productMastername, { offset: 50 })
          this.$refs.productMastername.focus()
        }
      },
      DeleteProduct(name,index){
        Swal.fire({
          type: 'warning',
          title: 'ลบ ใช่ หรือ ไม่',
          text: 'ต้องการลบ '+name+' ใช่ หรือ ไม่',
          confirmButtonText: 'Yes!',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.dataset.splice(index,1)
           }
        })
      },

      // optinn
      async OpenOption(is_prop){
        await this.headers_prop1()

        // เพิ่ม prop
        await this.PushOption(1) 
        this.dataset_option = await this.StartDataProduct(this.dataset_option)
        //this.stockqq.dataset = await this.StartDataStock(this.stockqq.dataset)
       
       

        this.stockqq.data_warehouse_list.forEach(element => {
          element.dataset= []
          var counter=1;
          
          element.dataset.push({
            "id":counter,
            "productMasterItemID":0,
            "prop": 1,
            "index": 0,
            "prop2": null,
            "index2": null,
            "prop1Description": null,
            "prop2Description": null,
            "buffer": null,
            "stockquantity":null,
          })
          
          counter++;
        });

        
        if (this.photo.photo_link != '' && this.photo.photo_link != null){ this.productMasterItem.photo_link = this.photo.photo_link }
        if (this.productMasterItem.id > 0){
          this.dataset_option[0].id = this.productMasterItem.id
          this.dataset_option[0].alertQuantity = this.productMasterItem.alertQuantity
          this.dataset_option[0].unitPrice = this.productMasterItem.unitPrice
          this.dataset_option[0].sku = this.productMasterItem.sku
          this.dataset_option[0].barcode = this.productMasterItem.barcode
          this.dataset_option[0].photo_link = this.productMasterItem.photo_link
          this.option[0].data[0].photo_link = this.productMasterItem.photo_link
        }

        // del photo
        await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
        if (this.productMasterItem.id == 0){ this.photo.photo_link = null }
        this.is_prop = is_prop
      },
      StartDataProduct(dataset_option){
        dataset_option = [
          {
            "id": null,
            "prop": 1,
            "index": 0,
            "prop2": null,
            "index2": null,
            "prop1Description": null,
            "prop2Description": null,
            "alertQuantity": null,
            "unitPrice": null,
            "sku": null,
            "barcode": null,
            "photo_link": null,
            "cubic1": null,
            "cubic2": null,
            "cubic3": null,
            "weight": null,
          }
        ]
        return dataset_option
      },
      StartDataStock(dataset_stock){
        dataset_stock = [
          {
            "prop": 1,
            "index": 0,
            "prop2": null,
            "index2": null,
            "prop1Description": null,
            "prop2Description": null,
            "buffer": null,
            "stockquantity":null,
          }
        ]
        return dataset_stock
      },
      PushOption(prop){
        this.option.push({
          "prop": prop,
          "name": null,
          "data": [
            {
              "is_base": false,
              "name": null,
              "photo_link_temp": null,
              "photo_link": null,
              "photo_uploand": {
                "file":[],
                "fileSize":0,
                "from_Picture":{},
                "from_path":''
              }
            },
          ]
        })
      },
      PushEditOption(prop,name){
        this.option.push({
          "prop": prop,
          "name": name,
          "data": []
        })
      },
      headers_prop1(){
        this.headers_option = [
          { align: 'center', text: this.use_language.prop1, value: 'prop1Description', sortable: false },
          { align: 'center', text:this.use_language.unitprice, value: 'unitPrice', sortable: false, width: '110px' },
          { align: 'center', text: this.use_language.sku, value: 'sku', sortable: false, width: '110px' },
          { align: 'center', text: this.use_language.barcode, value: 'barcode', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.cubic1+'('+this.use_language.cm +')', value: 'cubic1', sortable: false, width: '110px' },
          { align: 'right', text:this.use_language.cubic2+'('+this.use_language.cm +')', value: 'cubic2', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.cubic3+'('+this.use_language.cm +')', value: 'cubic3', sortable: false, width: '110px' },
          { align: 'right', text:  this.use_language.weight+'('+ this.use_language.gram +')', value: 'weight', sortable: false, width: '110px' },
        ],
        this.stockqq.headers = [
        { align: 'center', text: this.use_language.prop1, value: 'prop1Description', sortable: false, width: '60px' },
        { text: 'จำนวน Buffer',align: 'center',sortable: false,value: 'buffer', width: '60px'},
        { text: 'จำนวน สต๊อก',align: 'center',sortable: false,value: 'stockquantity', width: '80px'},
        ]
      },
      headers_prop2(){
        this.headers_option = [
          { align: 'center', text:this.use_language.prop1, value: 'prop1Description', sortable: false },
          { align: 'center', text: this.use_language.prop2, value: 'prop2Description', sortable: false },
          { align: 'center', text: this.use_language.unitprice, value: 'unitPrice', sortable: false, width: '110px' },
          { align: 'center', text: this.use_language.sku, value: 'sku', sortable: false, width: '110px' },
          { align: 'center', text: this.use_language.barcode, value: 'barcode', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.cubic1+'('+this.use_language.cm +')', value: 'cubic1', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.cubic2+'('+this.use_language.cm +')', value: 'cubic2', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.cubic3+'('+this.use_language.cm +')', value: 'cubic3', sortable: false, width: '110px' },
          { align: 'right', text: this.use_language.weight+'('+this.use_language.gram +')', value: 'weight', sortable: false, width: '110px' }
        ],
        this.stockqq.headers = [
        { align: 'center', text: this.use_language.prop1, value: 'prop1Description', sortable: false, width: '60px' },
        { align: 'center', text:this.use_language.prop2, value: 'prop2Description', sortable: false, width: '60px' },
        { text: 'จำนวน Buffer',align: 'center',sortable: false,value: 'buffer', width: '60px'},
        { text: 'จำนวน สต๊อก',align: 'center',sortable: false,value: 'stockquantity', width: '80px'},
        ]
      },
      async CloseOption(prop){
        // del picture
        if (prop==1){
          await this.DelPhotoOption()
        }

        // del option
        if (this.option.length==1){
          this.option = []
          for (var jj in this.stockqq.data_warehouse_list){
            this.stockqq.data_warehouse_list[jj].dataset=[];
            this.stockqq.data_warehouse_list[jj].dataset.push({ 
              "id":1,
              "productMasterItemID": 0,
              "prop": null,
              "index":null,
              "prop2":null,
              "index2":null,
              "prop1Description": null,
              "prop2Description": null,
              "buffer":null,
              "stockquantity":null
            });
          }
          this.stockqq.data_warehouse_list
        }
        else if (this.option.length>1){
          // ลบ prop
          for (var i in this.option){
            if (this.option[i]["prop"] == prop){
              this.option.splice(i,1)
            }
          }
          // อัพเดท prop
          for (var ii=0; ii<this.option.length; ii++){
            this.option[ii]["prop"] = ii+1
            // for (var data in this.option[ii]["data"]){
            //   this.option[ii]["data"][data]["is_base"] = false
            //   this.option[ii]["data"][data]["photo_link"] = null
            // }
          }
          await this.headers_prop1()
          await this.SetNameOptionIndex0()
          await this.CloseCalProduct()
        }

        // กลับไปเป็นแบบ ไม่มี prop
        if (this.option.length==0){
          this.is_prop = false
          this.stockqq.headers = [
            { text: 'จำนวน Buffer',align: 'center',sortable: false,value: 'buffer', width: '60px'},
            { text: 'จำนวน สต๊อก',align: 'center',sortable: false,value: 'stockquantity', width: '80px'},
          ]
        }
      },
      async DelPhotoOption(){
        if (this.option.length>0){
          for (var dp1 in this.option[0].data){
            if (this.option[0].data[dp1].photo_link!=this.option[0].data[dp1].photo_link_temp){
              await this.DelPhoto(this.option[0].data[dp1].photo_link,false)
            }
          }
        }
      },
      async DelPhotoOptionTemp(){
        if (this.option.length>0){
          for (var dp1 in this.option[0].data){
            if (this.option[0].data[dp1].photo_link!=this.option[0].data[dp1].photo_link_temp){
              await this.DelPhoto(this.option[0].data[dp1].photo_link_temp,false)
            }
          }
        }
      },
      SetNameOptionIndex0(){
        if (this.option[0]["name"]!='' && this.option[0]["name"]!=null){
          this.headers_option[0]["text"] = this.option[0]["name"]
          this.stockqq.headers[0]["text"] = this.option[0]["name"]
        }
      },
      async CloseCalProduct(){
        var dataset_option = []
        for (var i in this.option){
          for (var ii in this.option[i]["data"]){
            dataset_option = await this.AddDataProduct(dataset_option, parseInt(this.option[i]["prop"]), parseInt(ii), null, null, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null, null)
          }
        }

        for (var j1 in this.stockqq.data_warehouse_list){
          var datawarehouse=[]
          for (var j2 in this.option){
            for (var j3 in this.option[j2]["data"]){
              datawarehouse = await this.AddDataProduct2(datawarehouse, parseInt(this.option[j2]["prop"]), parseInt(j3), null, null, this.option[j2]["data"][j3]["name"], null )
            }
          }
          this.stockqq.data_warehouse_list[j1].dataset=datawarehouse;
        }
        this.dataset_option = dataset_option
      },
      async AddDataProduct(dataset_option, Pprop, Pindex, Pprop2, Pindex2, Pprop1Description, Pprop2Description, PalertQuantity, PunitPrice, Psku, Pbarcode, Pcubic1, Pcubic2, Pcubic3, Pweight){
        var id = null
        var prop = Pprop
        var index = Pindex
        var prop2 = Pprop2
        var index2 = Pindex2
        var prop1Description = Pprop1Description
        var prop2Description = Pprop2Description
        var alertQuantity = PalertQuantity
        var unitPrice = PunitPrice
        var sku = Psku
        var barcode = Pbarcode
        var photo_link = null
        var cubic1 = Pcubic1
        var cubic2 = Pcubic2
        var cubic3 = Pcubic3
        var weight = Pweight
        dataset_option.push({
          "id": id,
          "prop": prop,
          "index": index,
          "prop2": prop2,
          "index2": index2,
          "prop1Description": prop1Description,
          "prop2Description": prop2Description,
          "alertQuantity": alertQuantity,
          "unitPrice": unitPrice,
          "sku": sku,
          "barcode": barcode,
          "photo_link": photo_link,
          "cubic1": cubic1,
          "cubic2": cubic2,
          "cubic3": cubic3,
          "weight": weight
        })
     
        
      
        
      
        return dataset_option
      },
      async AddDataProduct2(dataset_option, Pprop, Pindex, Pprop2, Pindex2, Pprop1Description, Pprop2Description){
   
        var prop = Pprop
        var index = Pindex
        var prop2 = Pprop2
        var index2 = Pindex2
        var prop1Description = Pprop1Description
        var prop2Description = Pprop2Description
       
        var tempdata = [];
        var counter=1;
        for (let j = 0; j < dataset_option.length; j++) {
          tempdata.push({
            "id":counter,
            "productMasterItemID": dataset_option[j].productMasterItemID,
            "prop": dataset_option[j].prop,
            "index": dataset_option[j].index,
            "prop2": dataset_option[j].prop2,
            "index2": dataset_option[j].index2,
            "prop1Description": dataset_option[j].prop1Description,
            "prop2Description": dataset_option[j].prop2Description,
            "buffer":dataset_option[j].buffer,
            "stockquantity":dataset_option[j].stockquantity
          });
          counter++;
        }
        
        tempdata.push({
          "id":counter,
          "productMasterItemID":0,
          "prop": prop,
          "index": index,
          "prop2": prop2,
          "index2": index2,
          "prop1Description": prop1Description,
          "prop2Description": prop2Description,
          "buffer":null,
          "stockquantity":null
        });
        
      
        return tempdata
      },
      ChangeProp(prop,name){
        this.headers_option[prop-1]["text"] = 'ชื่อ'
        this.stockqq.headers[prop-1]["text"] = 'ชื่อ'
        if (name!='' && name!=null){
          this.headers_option[prop-1]["text"] = name
          this.stockqq.headers[prop-1]["text"] = name
        }
      },
      ChangeOptionInProp(prop,index,name){
        console.log("ChangeOptionInProp"  );
        var prop_name = null
        var index_name = null
        var prop_description_name = null
        if (prop==1){
          prop_name = "prop"
          index_name = "index"
          prop_description_name = "prop1Description"
        } else if (prop==2){
          prop_name = "prop2"
          index_name = "index2"
          prop_description_name = "prop2Description"
        }

        for (var i in this.dataset_option){
          if (this.dataset_option[i][prop_name]==prop && this.dataset_option[i][index_name]==index){
            this.dataset_option[i][prop_description_name] = name 
            for (var j in this.stockqq.data_warehouse_list){
              this.stockqq.data_warehouse_list[j].dataset[i][prop_description_name] = name
            }
          }
        }
      
     
      },
      async Del_Option2(prop,index){ 
        if (prop==1 && !this.option[0]["data"][index]["is_base"]){
          await this.DelPhoto(this.option[0]["data"][index]["photo_link"],this.option[0]["data"][index]["is_base"])
        }

        // ลบลิส option
        for (var i in this.option){
          if (this.option[i]["prop"] == prop){
            this.option[i]["data"].splice(index,1)
          }
        }

        var prop_name = null
        var index_name = null
        if (prop==1){
          prop_name = "prop"
          index_name = "index"
        } else if (prop==2){
          prop_name = "prop2"
          index_name = "index2"
        }

        

        // ลบลิส Data product
        var Dataproduct = [] 
        for (var ii in this.dataset_option){
          if (this.dataset_option[ii][prop_name] == prop && this.dataset_option[ii][index_name] != index){
            Dataproduct.push(this.dataset_option[ii])
          }
        }
        for (var j in this.stockqq.data_warehouse_list){
          var tempdata = []
          for (var j2 = 0; j2 < this.stockqq.data_warehouse_list[j].dataset.length; j2++) {
            if(this.stockqq.data_warehouse_list[j].dataset[j2][prop_name] ==prop && this.stockqq.data_warehouse_list[j].dataset[j2][index_name] != index){
              tempdata.push(this.stockqq.data_warehouse_list[j].dataset[j2])
            }
          }
          var counter=1;
          for (var j3 = 0; j3 < tempdata.length; j3++) {
            tempdata[j3].id = counter;
            counter++;
          } 
          this.stockqq.data_warehouse_list[j].dataset = tempdata;
        }
        

        // update index
        for (var iii in Dataproduct){
          if (parseInt(Dataproduct[iii][index_name])>parseInt(index)){
            Dataproduct[iii][index_name] -=1
          }
        }
        this.dataset_option = Dataproduct
      },
      async add_prop_description(prop){ 
    
        var index = null 
        for (var i in this.option){
          if (this.option[i]["prop"] == prop){
            this.option[i]["data"].push({
              "is_base": false,
              "name": null,
              "photo_link": null,
              "photo_link_temp": null,
              "photo_uploand": {
                "file":[],
                "fileSize":0,
                "from_Picture":{},
                "from_path":''
              }
            })
            index = this.option[i]["data"].length-1
          }
        } 
        await this.CalProduct(prop, index)
      },
      UpdatePhoto(index){
        this.pic_index = index
      },
      async AddOption(){ 
        await this.PushOption(2)
        await this.headers_prop2()
        await this.SetNameOptionIndex0()
       

        for (var i in this.dataset_option) {
          this.dataset_option[i]["prop2"] = this.option[this.option.length-1]["prop"]
          this.dataset_option[i]["index2"] = 0
          // this.stockqq.dataset[i]["prop2"] = this.dataset_option[i]["prop2"];
          // this.stockqq.dataset[i]["index2"] = this.dataset_option[i]["index2"];
          this.stockqq.data_warehouse_list.forEach(element => {
            
            element.dataset.forEach (element1 => {
              element1["prop2"]= this.option[this.option.length-1]["prop"];
              element1["index2"]=0;
            });
          });
          
        }
      },
      async CalProduct(prop, index){
        console.log( "CalProduct " + "prop : "+prop+", index : "+index);
 
        // เพิ่ม option
        if (this.option.length==1){
          this.dataset_option = await this.AddDataProduct(this.dataset_option, prop, index, null, null, null, null, null, null, null, null,null, null, null, null)
          
          for (let i = 0; i < this.stockqq.data_warehouse_list.length; i++) {
            this.stockqq.data_warehouse_list[i].dataset = await this.AddDataProduct2(this.stockqq.data_warehouse_list[i].dataset, prop, index, null, null,null,null )
          } 
          console.log(this.stockqq.data_warehouse_list);
        }

        if (this.option.length>1){ 
          var check_prop = 1
          if (prop==1){check_prop=2}
          var dataset_option = this.dataset_option
         
          for (var i in this.option) {
            // เอาแค่ ตัวเลือกที่1
            if (this.option[i]["prop"]==check_prop){
              for (var ii in this.option[i]["data"]) {
                if (check_prop==1){
                  // ตัวที่เพิ่มเป็น prop 2
                  dataset_option = await this.AddDataProduct(dataset_option, parseInt(this.option[i]["prop"]), parseInt(ii), prop, index, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null, null)
                
                  for (let jj = 0; jj < this.stockqq.data_warehouse_list.length; jj++) {
                    this.stockqq.data_warehouse_list[jj].dataset = await this.AddDataProduct2(this.stockqq.data_warehouse_list[jj].dataset, parseInt(this.option[i]["prop"]), parseInt(ii), prop, index, this.option[i]["data"][ii]["name"],null)
                    this.stockqq.data_warehouse_list[jj].dataset = await this.OrderByData(this.stockqq.data_warehouse_list[jj].dataset)
                  } 
                  
                 
                }else{
                  dataset_option = await this.AddDataProduct(dataset_option, prop, index, parseInt(this.option[i]["prop"]), parseInt(ii), null, this.option[i]["data"][ii]["name"], null, null, null, null, null, null, null, null)
                
                  for (let jj = 0; jj < this.stockqq.data_warehouse_list.length; jj++) {
                    this.stockqq.data_warehouse_list[jj].dataset = await this.AddDataProduct2(this.stockqq.data_warehouse_list[jj].dataset,  prop, index, parseInt(this.option[i]["prop"]), parseInt(ii), null, this.option[i]["data"][ii]["name"])
                    this.stockqq.data_warehouse_list[jj].dataset = await this.OrderByData(this.stockqq.data_warehouse_list[jj].dataset)
                  } 
                  
                }
              }
            }
          }
          this.dataset_option = dataset_option
          // order by index prop1
          this.dataset_option = await this.OrderByData(this.dataset_option)

          

        }
      },
      OrderByData(dataset_option){
        var no = []
        var temp = []
        // เก็บ index
        for (var i in dataset_option){
          if (!no.includes(dataset_option[i]["index"])){
            no.push(dataset_option[i]["index"])
          }
        }
        no.sort()
        for (var ii in no){
          for (var iii in dataset_option){
            if (no[ii]==dataset_option[iii]["index"]){
              temp.push(dataset_option[iii])
            }
          }
        }
        return temp
      },

      // popup select product
      async OpenPopup(limits,offsets){
        this.loading = true
        this.searchStockModelPopup = null
        this.addStockPopup.Select_Product = []
        this.addStockPopup.Data = []
        this.addStockPopup.ProductMasterItemID_List = []
        this.addStockPopup.loading = false
        this.Popupsearch = null

        for (var i in this.dataset){
          if (!this.addStockPopup.ProductMasterItemID_List.includes(this.dataset[i]["id"])){
            this.addStockPopup.ProductMasterItemID_List.push(this.dataset[i]["id"])
          }
        }

        this.page = 1
        this.pageTemp = 1
        this.getSelectProduct(limits,offsets,this.searchStockModelPopup,this.page)
      },
      async getSelectProduct(limits,offsets,searchstock,page){
        let name = (this.StockSearchType == "ชื่อ"? searchstock:null);
        let sku = (this.StockSearchType == "sku"? searchstock:null);
        let barcode = (this.StockSearchType == "barcode"? searchstock:null);
        let response = await axios.post(stockService_dotnet+'Stock/get-stockmaster-list', {
          "shopId": localStorage.getItem('shop_id'),
          "sku": sku,
          "name": name,
          "barcode": barcode,
          "skipStockMasterIdList": this.addStockPopup.ProductMasterItemID_List,
          "skip": offsets,
          "take": limits,
        }, {headers: this.header_token} )
        if (response.status==200) {
          this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.addStockPopup.Data = []
          for (var i in response.data.resultData){
            response.data.resultData[i]["selectProduct"] = false
            this.addStockPopup.Data.push(response.data.resultData[i])
          }
          this.SetUp_Product()
          this.loading = false
          this.addStockPopup.Dialog = true
        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async GetCategory() {
                
        let response = await    axios.post(stockService_dotnet+'Stock/get-product-category-list', {   
            // 'productCategoryID': null,
          }, {headers: this.header_token})
                if (response.status == 204 || response.status == 200) {
                    if (response.data != "" && response.data != null) {
                      this.Category_list = [{"id": 0, "categoryName": "--เลือกหมวดหมู่--"}]
                      if (response.data.length>0){
                      for (var x in  response.data){
                        this.Category_list.push({
                          "id": response.data[x].id,
                          "categoryName": response.data[x].categoryName,
                          })
                       } 
                      }
                    }
                }
            },
      async PopupsearchStock(limits,offsets){
        if (this.searchStockModelPopup!=null){
          this.Popupsearch = this.searchStockModelPopup.trim()
          this.loading = true
          if (this.Popupsearch.length>0){
            this.getSelectProduct(limits,offsets,this.Popupsearch,1)
          }else{
            this.getSelectProduct(limits,offsets,null,1)
          }
        }
      },
      ProductSelect(item,index){
        this.addStockPopup.Data[index]["selectProduct"] = !item["selectProduct"]
        if (item["selectProduct"]){ //นำเข้า Select_Product
          this.addStockPopup.Select_Product.splice(0, 0, item)
        }else{ //นำออก Select_Product
          var data = this.addStockPopup.Select_Product
          for (var i in data){
            if (data[i]["id"] == item["id"]){
              this.addStockPopup.Select_Product.splice(i,1)
              break
            }
          }
        }
      },
      ProductSelectCheckbox(item,index){
        if (item["selectProduct"]){ //นำเข้า Select_Product
          this.addStockPopup.Select_Product.splice(0, 0, item)
        }else{ //นำออก Select_Product
          var data = this.addStockPopup.Select_Product
          for (var i in data){
            if (data[i]["id"] == item["id"]){
              this.addStockPopup.Select_Product.splice(i,1)
              break
            }
          }
        }
      },
      SetUp_Product(){
        var StockShopID_list = []
        for (var i in this.addStockPopup.Select_Product){
          StockShopID_list.push(this.addStockPopup.Select_Product[i]["id"])
        }
        for (var a in this.addStockPopup.Data){
          if (StockShopID_list.includes(this.addStockPopup.Data[a]["id"])){
            this.addStockPopup.Data[a]["selectProduct"] = true
          }
        }
      },
      async Submit_addStockPopup(){
        this.addStockPopup.loading = true
        this.addStockPopup.Select_Product = await this.OrderBy(this.addStockPopup.Select_Product)
        for (var i in this.addStockPopup.Select_Product){
          this.addStockPopup.Select_Product[i]["quantity"] = null
          this.dataset.splice(0, 0, this.addStockPopup.Select_Product[i])
        }
         
        this.addStockPopup.loading = false
        this.addStockPopup.Dialog = false
      },

      // AvatarCropper
      async handleUploading(from_data, xhr) {
        var file_type = from_data.get("file").type.split("/")
        from_data.append("name", 'shop'+localStorage.getItem('shop_id')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1])
        from_data.append('path', 'Shop/')
        if (this.is_prop && !this.productMaster.isProductSetTemp){
          var photo_uploand = this.option[0]["data"][this.pic_index]["photo_uploand"]
          photo_uploand['from_Picture'] = from_data
          photo_uploand['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/'+'shop'+localStorage.getItem('shop_id')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1]
        }else{
          this.photo.photo_uploand['from_Picture'] = from_data
          this.photo.photo_uploand['from_path'] = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/'+'shop'+localStorage.getItem('shop_id')+this.DateNowNotNull()+'0-'+file_type[0]+'-'+file_type[1]
        }
        this.message = "uploading...";
      },
      async handleUploaded(response, from_data, xhr) {
        if (response=="OK") {
          if (this.is_prop && !this.productMaster.isProductSetTemp){
            await this.DelPhoto(this.option[0]["data"][this.pic_index]["photo_link"],this.option[0]["data"][this.pic_index]["is_base"])
            this.option[0]["data"][this.pic_index]["photo_link"] = this.option[0]["data"][this.pic_index]["photo_uploand"]['from_path']
            this.option[0]["data"][this.pic_index]["is_base"] = false
          }else{
            await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
            this.photo.photo_link = this.photo.photo_uploand['from_path']
            this.photo.is_base = false
          }
          this.message = "user avatar updated.";
        }
      },
      handleCompleted(response, from_data, xhr) {
        this.message = "upload completed.";
      },
      handlerError(message, type, xhr) {
        this.message = "Oops! Something went wrong...";
      },
      async DelPhoto(photoLink,is_base){
        if (photoLink!='' && photoLink!=null && !is_base){
          var pathRemove = photoLink
          pathRemove = pathRemove.split("/");
          pathRemove = pathRemove[3]+'/'+pathRemove[4]
          let response = await axios.post(globalService+'removePicture', {'path':pathRemove})
        }
      },
    async get_authorize_staffshop () {
          if(localStorage.getItem("220") == 2 || localStorage.getItem("230") == 2 )
          {
           this.authorize = true
          }
      },
      async get_warehouse_list () {
          let res1 = await axios.post(shopService_dotnet + 'Shop/get-warehouse-list',{
            "shopID": parseInt(localStorage.getItem('shop_id')),
          } , { headers: this.header_token})
          // this.stockqq.data_warehouse_list = res1.data.filter(x => !x.isPackhaiWarehouse)
          this.stockqq.data_warehouse_list = [];
          for (var x in res1.data) {
          if(!res1.data[x].isPackhaiWarehouse){
            this.stockqq.data_warehouse_list.push({
              dataset:[{
                "id":1,
                "productMasterItemID": 0,
                "prop": null,
                "index": null,
                "prop2": null,
                "index2":null,
                "prop1Description":null,
                "prop2Description":null,
                "buffer":null,
                "stockquantity":null
              }],
              warehouseID: res1.data[x].id,
              name: res1.data[x].name,
            });
          }
        }
      },

      // size จอ
      handleResize() {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
        if (this.window.width < 600) {
          this.size = 'xs'
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = 'sm'
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = 'md'
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = 'lg'
        } else if (this.window.width >= 1904) {
          this.size = 'xl'
        }
      },

      // วันที่
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      allowedDates: val => val >= new Date().toISOString().substr(0, 10),

      // เรียกใช้
      ChackEditRdo(id){
        if (id==0 || this.countStockshop ==0){
          return false
        }
        return true
      },
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.loading = true
          await this.getSelectProduct(this.limits,offsets,this.Popupsearch,this.page)
          this.pageTemp = this.page
        }
      },
      async OrderBy(data){
        var dataset = []
        for (var i=data.length-1; i>=0; i--){
          dataset.push(data[i])
        }
        return dataset
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      async toBack(path){
        // del photo
        await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
        await this.DelPhotoOption()
        this.$router.push('/'+path)
      },
      isNumberWithDot,
      isNumberWNoDot,
      formatMoney,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      formatDatetime,
      formatDate_thai,
      formatDate_eng,
      get_languages,
    },
  }
</script>

<style scoped>
  >>>.v-input__prepend-inner{
    margin-top: 10px;
  }

  >>>.v-card {
    position: static !important;
  }

  >>>.vue-avatar-cropper-demo {
    max-width: 18em;
    margin: 0 auto;
  }
  >>>.avatar {
    width: 160px;
    border-radius: 6px;
    display: block;
    margin: 20px auto;
  }
</style>
